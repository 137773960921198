import React, { useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CloseButton } from '../closeButton'
import { Contact } from '../Contact/Contact'
import { AlleThemenButton, PortfolioButton } from '../page/Button'
import { useQuery } from 'react-query'
import * as api from '../../CockData'
import './portStyles.css'

export interface ArrTypes {
  coverimage: {
    path: string
  }
  id: number
  titleCover: string
  contentCover: string
  subtitleCover: string
  sectionRoot: string
  sectionTitle: string
}

export const Portfolio = (): JSX.Element => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const history = useHistory()
  const logoFirma = useRef('')
  const topTitle = useRef('')
  const downTitle = useRef('')
  const arr: any = []
  const arrTop: ArrTypes[] = []
  const arrDown: ArrTypes[] = []
  const { data } = useQuery('Collections', api.getData)
  data &&
    data.map((y: any) => {
      if (exhibitor === y.Firmennamen.Firmennamen) {
        logoFirma.current = y.Firmennamen.Logo.path
        y.Firmennamen.Portfolio &&
          y.Firmennamen.Portfolio.map((ele: any, i: number) => {
            ele.value.id = i + 1
            arr.push(ele.value)
            if (ele.value.sectionRoot === 'top') {
              arrTop.push(ele.value)
              topTitle.current = arrTop[0].sectionTitle
            } else {
              arrDown.push(ele.value)
              downTitle.current = arrDown[0].sectionTitle
            }
            //setResult([...result, ele.value])
          })
      }
    })

  //Redirect after 3 minuten
  const lastTouch = useRef(0)
  const timeoutClick = useRef<NodeJS.Timeout | null>(null)
  const mainClick = document.getElementById('main')
  mainClick &&
    mainClick.addEventListener('touchstart', () => {
      lastTouch.current = new Date().getMinutes()
      timeoutClick.current = setInterval(function () {
        if (new Date().getMinutes() - lastTouch.current >= 3) {
          window.location.href = `/${machine}`
          timeoutClick.current && clearInterval(timeoutClick.current)
        }
      }, 1000)
    })

  return (
    <div className='absolute left-0 right-0 top-0 inset-0 select-none '>
      <div className='page grid page-grid-template h-full'>
        <div
          className='h-40 bg-headblue relative bg-cover drop-shadow-2xl rounded-b-xl'
          style={{
            backgroundImage:
              'url(https://source.unsplash.com/TcrkAz1yYPI/1600x500)'
          }}
        >
          <div
            className='object-cover absolute top-0 left-0 w-full h-full backdrop-filter
            backdrop-blur-2xl backdrop-brightness-20 bg-headblue bg-opacity-25 flex
            flex-row-reverse justify-between '
          >
            <div className='flex justify-end pt-9 pr-40 pb-8'>
              <img
                src={logoFirma.current}
                className='block h-full w-auto'
              ></img>
            </div>
            <h3 className='pl-36 pb-2 text-white my-auto'>Portfolio:</h3>
          </div>
        </div>
        <main className='bg-lightgray pl-36 pt-8 pb-2 grid grid-cols-1 gap-32 overflow-hidden'>
          <div className='grid grid-rows-2 overflow-hidden'>
            <section className='flex flex-col overflow-hidden'>
              <h3 className='mb-1'>{topTitle.current}</h3>
              <ImageGallery arrTop={arrTop} />
            </section>
            <section className='flex flex-col overflow-hidden'>
              <h3 className='mb-1'>{downTitle.current}</h3>
              <ImageGalleryDown arrDown={arrDown} />
            </section>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export interface FooterLogo {
  logoFirma: string
}
const Footer = () => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const history = useHistory()
  return (
    <div className='flex flex-row justify-between bg-lightgray'>
      <div className='flex-1 pl-10 pt-22 pb-16 h-16'>
        <CloseButton
          colorFul
          action={() => history.push(`/${exhibitor}/${machine}`)}
        />
      </div>
      <div className='flex-1 text-center flex flex-row justify-between pt-22'>
        <AlleThemenButton />
        <Link
          to={`/${exhibitor}/${machine}`}
          className='flex flex-col items-center'
        >
          <span className='text-white filter drop-shadow-lg w-24 h-24 rounded-full bg-gradient-to-br from-lime to-turquoise grid place-content-center mb-9'>
            <i className='text-icon icon icon-Pfeil2' />
          </span>
          <p>Zur Story</p>
        </Link>
        <PortfolioButton />
      </div>
      <div className='flex-1 pl-12 pr-2 relative '>
        <Contact />
      </div>
    </div>
  )
}

interface GalleryProps {
  className?: string
}
const Gallery = ({
  className,
  children
}: React.PropsWithChildren<GalleryProps>) => {
  return (
    <div
      className={`overflow-x-auto w-full flex-1 flex scroll-snap-x ${
        className ? className : ''
      }`}
    >
      <div className='flex flex-row flex-nowrap flex-grow gap-14 flex-auto pb-10 '>
        {children}
      </div>
    </div>
  )
}

const images = Array(9)
  .fill(0)
  .map((_, i) => i)

export interface GalleryTypes {
  coverimage: {
    path: string
  }
  id: number
  titleCover: string
  contentCover: string
  subtitleCover: string
  sectionRoot: string
}

const ImageGallery = (props: { arrTop: GalleryTypes[] }) => {
  return (
    <Gallery>
      {props.arrTop.map((x: GalleryTypes, i: number) => (
        <Link key={i} to={`page/${x.id}/portfolio`}>
          <div
            style={{
              backgroundImage: `url('${x.coverimage.path}')`,
              width: '25vw',
              height: '12vw',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex'
            }}
            className='flex-1 block min-h-0 last:mr-1 cursor-pointer rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9'
          >
            <div className='flex flex-col px-5 pb-24 self-end'>
              <div className='text-white breaking-words max-w-lg'>
                {x.titleCover}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </Gallery>
  )
}

const ImageGalleryDown = (props: { arrDown: GalleryTypes[] }) => {
  return (
    <Gallery>
      {props.arrDown.map((x: GalleryTypes, i: number) => (
        <Link key={i} to={`page/${x.id}/portfolio`}>
          <div
            style={{
              backgroundImage: `url('${x.coverimage.path}')`,
              width: '25vw',
              height: '12vw',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex'
            }}
            className='flex-1 block min-h-0 last:mr-1 cursor-pointer rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9'
          >
            <div className='flex flex-col px-5 pb-24 self-end'>
              <div className='text-white breaking-words max-w-lg'>
                {x.titleCover}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </Gallery>
  )
}
