import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player/file'
import './page.css'
import { CloseButton } from '../closeButton'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Contact } from '../Contact/Contact'
import { AlleThemenButton, PortfolioButton } from './Button'
import { useQuery } from 'react-query'
import * as api from '../../CockData'

export interface PageTypes {
  coverimage: {
    path: string
  }
  id: number
  titleCover: string
  contentCover: string
  subtitleCover: string
  sectionRoot: string
  mainContent: string
  mainContentSubTitle2: string
  mainContent2: string
  mainContentSubTitle3: string
  mainContent3: string
  mainContentSubTitle4: string
  mainContent4: string
}

export interface TopicTypes {
  topic_id: string
  content: string
  image: {
    path: string
  }
  topic_title: string
}

export const Page = (): JSX.Element => {
  const arrThemen: any = []
  const arrTopic: any = []
  const arrPortfolio: any = []
  const arrStory: any = []
  const logoFirma = useRef('')
  const history = useHistory()

  const {
    id,
    section,
    exhibitor,
    machine
  }: { section: string; id: string; exhibitor: string; machine: string } =
    useParams()
  const topTitle = useRef('')
  const { status: StatusTopic, data: dataTopic } = useQuery(
    'topics',
    api.topicAPI
  )
  const { data, status } = useQuery('Collections', api.getData)

  status === 'success' &&
    data.map((x: any, i: number) => {
      if (x.Firmennamen.Firmennamen === exhibitor) {
        logoFirma.current = x.Firmennamen.Logo.path
        if (section === 'themen') {
          StatusTopic === 'success' &&
            dataTopic.map((t: TopicTypes) => {
              if (id === t.topic_id) {
                topTitle.current = t.topic_title
                arrTopic.push(t)
              }
            })
          x.Firmennamen.Themen &&
            x.Firmennamen.Themen.map((t: any, i: number) => {
              t.value.id = i + 1
              const contentId = t.value.id.toString()
              if (id === contentId) {
                //topTitle.current = t.value.titleCover
                arrThemen.push(t.value)
              }
            })
        } else if (section === 'portfolio') {
          x.Firmennamen.Portfolio &&
            x.Firmennamen.Portfolio.map((t: any, i: number) => {
              t.value.id = i + 1
              const contentId = t.value.id.toString()
              if (id === contentId) {
                topTitle.current = t.value.titleCover
                arrPortfolio.push(t.value)
              }
            })
        } else {
          x.Firmennamen.Story &&
            x.Firmennamen.Story.map((t: any, i: number) => {
              t.value.id = i
              t.value.id === 0 && t.value.id + 1
              const contentId = t.value.id.toString()
              if (id === contentId) {
                topTitle.current = t.value.titleCover
                arrStory.push(t.value)
              }
            })
        }
      }
    })

  useEffect(() => {
    window.location.hash = 'hello'
    return () => {
      // Clean up the animation just in case is here
    }
  }, [])

  //Redirect after 3 minuten
  const lastTouch = useRef(0)
  const timeoutClick = useRef<NodeJS.Timeout | null>(null)
  const mainClick = document.getElementById('main')
  mainClick &&
    mainClick.addEventListener('touchstart', () => {
      lastTouch.current = new Date().getMinutes()
      timeoutClick.current = setInterval(function () {
        if (new Date().getMinutes() - lastTouch.current >= 3) {
          window.location.href = `/${machine}`
          timeoutClick.current && clearInterval(timeoutClick.current)
        }
      }, 1000)
    })

  return (
    <div className='absolute left-0 right-0 top-0 inset-0 select-none test '>
      <div className='page grid page-grid-template h-full'>
        <div
          className='h-40 bg-headblue relative bg-cover'
          style={{
            backgroundImage:
              'url(https://source.unsplash.com/TcrkAz1yYPI/1600x500)'
          }}
        >
          <div
            className='object-cover absolute top-0 left-0 w-full h-full backdrop-filter
            backdrop-blur-2xl backdrop-brightness-20 bg-headblue bg-opacity-25 flex
            flex-row-reverse justify-between '
          >
            <div className='flex justify-end pt-9 pr-40 pb-8'>
              <img
                src={logoFirma.current}
                className='block h-full w-auto'
              ></img>
            </div>
            <h3 className='pl-36 pb-2 text-white my-auto'>
              {topTitle.current}
            </h3>
          </div>
        </div>
        <main className='bg-lightgray pl-36 pt-14 pb-2 grid main-grid-template gap-32 overflow-hidden'>
          <article
            className='overflow-y-auto pr-2 max-h-full smooth-scroll max-w-screen-xl'
            style={{ width: '900px' }}
          >
            {section === 'themen'
              ? arrTopic.map((x: TopicTypes, i: number) => (
                  <div key={i}>
                    <p>{x.content}</p>
                  </div>
                ))
              : section === 'portfolio'
              ? arrPortfolio.map((z: PageTypes, i: number) => (
                  <div key={i}>
                    <h3>{z.titleCover}</h3>
                    <p>{z.mainContent}</p>
                    <h3>{z.mainContentSubTitle2}</h3>
                    <p>{z.mainContent2}</p>
                    <h3>{z.mainContentSubTitle3}</h3>
                    <p>{z.mainContent3}</p>
                    <h3>{z.mainContentSubTitle4}</h3>
                    <p>{z.mainContent4}</p>
                  </div>
                ))
              : arrStory.map((y: PageTypes, i: number) => (
                  <div key={i}>
                    <h3>{y.titleCover}</h3>
                    <p>{y.mainContent}</p>
                    <h3>{y.mainContentSubTitle2}</h3>
                    <p>{y.mainContent2}</p>
                    <h3>{y.mainContentSubTitle3}</h3>
                    <p>{y.mainContent3}</p>
                    <h3>{y.mainContentSubTitle4}</h3>
                    <p>{y.mainContent4}</p>
                  </div>
                ))}
          </article>
          <aside className='grid grid-rows-2 overflow-hidden'>
            <section
              className={`flex flex-col overflow-hidden ${
                arrTopic.length > 0
                  ? 'h-66 w-auto filter rounded-xl drop-shadow-xl'
                  : ''
              }`}
            >
              {arrTopic.length > 0 ? (
                <img
                  className='h-full w-auto filter rounded-xl drop-shadow-xl'
                  src={`http://cms.hybrid-x.de/${arrTopic[0].image.path}`}
                />
              ) : (
                <>
                  <h3>Bildergalerie</h3>
                  <ImageGallery
                    arrTopic={arrTopic}
                    arrThemen={arrThemen}
                    arrPortfolio={arrPortfolio}
                    arrStory={arrStory}
                  />
                </>
              )}
            </section>
            <section className='flex flex-col overflow-hidden'>
              {arrTopic.length > 0 ? (
                <div className='hidden'></div>
              ) : section === 'portfolio' ? (
                <>
                  <h3 className='mb-1'>Videos</h3>
                  <VideoGallery
                    arrThemen={arrThemen}
                    arrPortfolio={arrPortfolio}
                    arrStory={arrStory}
                  />
                </>
              ) : section === 'story' ? (
                <>
                  <h3 className='mb-1'>Videos</h3>
                  <VideoGallery
                    arrThemen={arrThemen}
                    arrPortfolio={arrPortfolio}
                    arrStory={arrStory}
                  />
                </>
              ) : (
                <>
                  <h3 className='mb-1'>Videos</h3>
                  <VideoGallery
                    arrThemen={arrThemen}
                    arrPortfolio={arrPortfolio}
                    arrStory={arrStory}
                  />
                </>
              )}
            </section>
          </aside>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export interface FooterLogo {
  logoFirma: string
}
const Footer = () => {
  const {
    id,
    section,
    exhibitor,
    machine
  }: { section: string; id: string; exhibitor: string; machine: string } =
    useParams()
  const history = useHistory()

  const handleBacking = () => {
    if (section === 'themen' || section === 'portfolio') {
      return `/${exhibitor}/${machine}/${section}`
    } else {
      return `/${exhibitor}/${machine}`
    }
  }
  return (
    <div className='flex flex-row justify-between bg-lightgray'>
      <div className='flex-1 pl-10 pt-22 pb-16 h-16'>
        <CloseButton colorFul action={() => history.push(handleBacking())} />
      </div>
      <div className='flex-1 text-center flex flex-row justify-between pt-22'>
        <AlleThemenButton />
        <Link
          to={`/${exhibitor}/${machine}`}
          className='flex flex-col items-center'
        >
          <span className='text-white filter drop-shadow-lg w-24 h-24 rounded-full bg-gradient-to-br from-lime to-turquoise grid place-content-center mb-9'>
            <i className='text-icon icon icon-Pfeil2' />
          </span>
          <p>Zur Story</p>
        </Link>
        <PortfolioButton />
      </div>
      <div className='flex-1 pl-12 pr-2 relative '>
        <Contact />
      </div>
    </div>
  )
}

interface GalleryProps {
  className?: string
  id?: string
  oneImgPort?: number
  oneImgStory?: number
}
const Gallery = ({
  className,
  children,
  id,
  oneImgPort,
  oneImgStory
}: React.PropsWithChildren<GalleryProps>) => {
  return (
    <div
      id={id}
      className={`overflow-x-auto flex-1 flex scroll-snap-x ${
        className ? className : ''
      } ${
        oneImgPort === 1
          ? 'w-730 h-auto'
          : oneImgStory === 1
          ? 'w-730 h-auto'
          : ''
      }`}
    >
      <div className='flex flex-row flex-nowrap flex-grow gap-14 flex-auto pb-10 '>
        {children}
      </div>
    </div>
  )
}

export interface GalleryContentTest {
  contentImageGallery: PathsTypes
}

export interface PathsTypes {
  meta: string
  path: string
}

const images = Array(35)
  .fill(0)
  .map((_, i) => i)

const ImageGallery = (props: {
  arrTopic: GalleryContentTest[]
  arrThemen: GalleryContentTest[]
  arrPortfolio: GalleryContentTest[]
  arrStory: GalleryContentTest[]
}) => {
  const { section }: { section: string; id: string; exhibitor: string } =
    useParams()
  const [active, setActive] = useState(-1)
  const close = () => {
    setActive(-1)
    //window.location.pathname = ''
  }
  //Themen Data
  const arrThemenGallery: any = []
  const galleryImages: any = []
  const metasArray: any = []
  props.arrThemen.map((x: any) => {
    arrThemenGallery.push(x.contentImageGallery)
  })
  arrThemenGallery.map((nested: any) =>
    nested.map((element: any) => galleryImages.push(element))
  )
  galleryImages.map((test: any) => {
    if (test.meta.title !== '') {
      metasArray.push(test.meta.title)
    } else {
      metasArray.push('')
    }
  })
  //Portfolio Data
  const arrPortfolioGallery: any = []
  const galleryImagesPort: any = []
  const metasArrayPort: any = []
  props.arrPortfolio.map((x: any) => {
    if (x.contentImageGallery !== null) {
      arrPortfolioGallery.push(x.contentImageGallery)
    }
  })

  arrPortfolioGallery.map((nested: any) =>
    nested.map((element: any) => galleryImagesPort.push(element))
  )

  galleryImagesPort.map((text: any) => {
    if (text.meta.title !== '') {
      metasArrayPort.push(text.meta.title)
    } else {
      metasArrayPort.push('')
    }
  })
  //Story Data
  const arrStoryGallery: any = []
  const galleryImagesStory: any = []
  const metasArrayStory: any = []
  props.arrStory.map((x: any) => {
    if (x.contentImageGallery !== null) {
      arrStoryGallery.push(x.contentImageGallery)
    }
  })
  arrStoryGallery.map((nested: any) =>
    nested.map((element: any) => galleryImagesStory.push(element))
  )
  galleryImagesStory.map((text: any) => {
    if (text.meta.title !== '') {
      metasArrayStory.push(text.meta.title)
    } else {
      metasArrayStory.push('')
    }
  })

  //Topics Data
  const arrTopicGallery: any = []
  props.arrTopic.map((x: any) => {
    arrTopicGallery.push(x.image.path)
  })

  //scroll gallery title
  const modal = document.getElementById('modal-test')
  const firstRow = document.getElementById('first-row')
  const lastItemValue = useRef(0)
  const [testy, setTesty] = useState(0)
  const testScroll = document.getElementById('test-scroll')
  testScroll?.addEventListener('scroll', () => {
    const i = testScroll.scrollLeft / testScroll.clientWidth
    const imagen = document.getElementById(`${'g' + Math.round(i)}`)
    if (galleryImagesStory.length > 0) {
      const lastItem = galleryImagesStory[galleryImagesStory.length - 1]
      lastItemValue.current = galleryImagesStory.lastIndexOf(lastItem)
    } else if (galleryImages.length > 0) {
      const lastItem = galleryImages[galleryImages.length - 1]
      lastItemValue.current = galleryImages.lastIndexOf(lastItem)
    } else {
      const lastItem = galleryImagesPort[galleryImagesPort.length - 1]
      lastItemValue.current = galleryImagesPort.lastIndexOf(lastItem)
    }
    if (imagen?.clientWidth) {
      //console.log(imagen?.clientWidth)
      //width fixed image test 1176
      /// espacio 480
      const test = testScroll.scrollLeft / (imagen?.clientWidth + 156)
      setTesty(Math.round(test))
      /*const FinalI = testScroll.scrollLeft / (imagen?.clientWidth + 156)
      if (lastItemValue.current !== Math.round(FinalI)) {
        setTesty(Math.floor(FinalI))
      } else {
        setTesty(Math.round(FinalI))
      }*/
    }
  })

  return (
    <Gallery
      oneImgPort={galleryImagesPort.length}
      oneImgStory={galleryImagesStory.length}
    >
      {section === 'themen'
        ? arrTopicGallery.map((x: PathsTypes, i: number) => (
            <img
              src={`http://cms.hybrid-x.de/${x}`}
              key={i}
              className='flex-1 block min-h-0 last:mr-20 cursor-pointer
          w-auto h-full rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9 object-contain'
              onClick={() => {
                setActive(i)
                setTimeout(() => (window.location.hash = 'g' + i), 500)
              }}
            />
          ))
        : section === 'portfolio'
        ? galleryImagesPort.map((x: PathsTypes, i: number) => (
            <img
              src={`${x.path}`}
              key={i}
              className='flex-1 block min-h-0 last:mr-20 cursor-pointer
          w-auto h-full rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9 object-contain'
              onClick={() => {
                setActive(i)
                setTimeout(() => (window.location.hash = 'g' + i), 500)
              }}
            />
          ))
        : galleryImagesStory.map((x: PathsTypes, i: number) => (
            <img
              src={`${x.path}`}
              key={i}
              className='flex-1 block min-h-0 last:mr-20 cursor-pointer
          w-auto h-full rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9 object-contain'
              onClick={() => {
                setActive(i)
                setTimeout(() => (window.location.hash = 'g' + i), 500)
              }}
            />
          ))}
      <div className='pl-8'></div>
      <div
        className={`bg-darkgray bg-opacity-70 absolute inset-0 z-50
        backdrop-filter backdrop-blur-sm backdrop-brightness-50 animate-fadein
        overflow-hidden ${active < 0 ? ' hidden' : ''}`}
        id='modal-test'
        onTouchStart={(e) => {
          if (e.target == modal || e.target == firstRow) {
            close()
          }
        }}
      >
        <div className='grid page-gallery-grid-template '>
          <div className='flex flex-col'>
            {/* 1 row */}
            <div id='first-row' className='flex h-4'>
              <></>
            </div>
            {/* 2 row */}
            <div className='h-4'></div>
            {/* 3 row */}
            <div className='overflow-y-hidden h-gallery flex'>
              {/* FIRST ROW IMAGES */}
              <Gallery
                id='test-scroll'
                className='scroll-px-9 pl-730 smooth-scroll'
              >
                {section === 'themen'
                  ? galleryImages.map((x: PathsTypes, i: number) => (
                      <div
                        key={'g' + i}
                        id={'g' + i}
                        style={{
                          backgroundImage: `url('${x.path}')`,
                          width: '100%',
                          height: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center'
                        }}
                        className={`flex-1 block min-h-0 w-full h-auto
                rounded-xl drop-shadow-xl filter scroll-align-start border-4
                border-transparent transition-colors
                duration-300 ease-in-out ${testy === i ? '' : ''}`}
                      >
                        <div className='flex flex-col px-5 pt-8 pb-4 w-test-one h-full'></div>
                      </div>
                    ))
                  : section === 'portfolio'
                  ? galleryImagesPort.map((x: PathsTypes, i: number) => (
                      <div
                        key={'g' + i}
                        id={'g' + i}
                        style={{
                          backgroundImage: `url('${x.path}')`,
                          width: '100%',
                          height: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center'
                        }}
                        className={`flex-1 block min-h-0 w-full h-auto
                    rounded-xl drop-shadow-xl filter scroll-align-start border-4
                    border-transparent transition-colors
                    duration-300 ease-in-out ${testy === i ? '' : ''}`}
                      >
                        <div className='flex flex-col px-5 pt-8 pb-4 w-test-one h-full'></div>
                      </div>
                    ))
                  : galleryImagesStory.map((x: PathsTypes, i: number) => (
                      <div
                        key={'g' + i}
                        id={'g' + i}
                        style={{
                          backgroundImage: `url('${x.path}')`,
                          width: '100%',
                          height: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center'
                        }}
                        className={`flex-1 block min-h-0 w-full h-auto
                rounded-xl drop-shadow-xl filter scroll-align-start border-4
                border-transparent transition-colors
                duration-300 ease-in-out ${testy === i ? '' : ''}`}
                      >
                        <div className='flex flex-col px-5 pt-8 pb-4 w-test-one h-full'></div>
                      </div>
                    ))}
                <div className='pl-lefty h-full'></div>
              </Gallery>
            </div>
            {/* 4 row */}
            {/* <div className='pl-test-left h-4 text-white flex flex-col pb-2'>
              <h4 className='pl-1.5 pb-11 text-white'>
                {metasArray.length > 0
                  ? metasArray[testy]
                  : metasArrayPort.length > 0
                  ? metasArrayPort[testy]
                  : metasArrayStory[testy]}
              </h4>
              <h4 className='font-thin italic text-md pb-2'></h4>
            </div> */}
            <div id='first-row' className='flex h-screen'>
              <CloseButton action={() => close()} />
            </div>
          </div>
        </div>
      </div>
    </Gallery>
  )
}
const pad = (txt: string) => txt.padStart(2, '0')

const format = (seconds: number) => {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds().toString())
  if (hh) {
    return `${hh}:${pad(mm.toString())}:${ss}`
  }
  return `${mm}:${ss}`
}

const videoCSS =
  'mb-6 bg-clip-padding backdrop-blur-xl  rounded-full bg-opacity-40 bg-white grid place-content-center appereance-none focus:outline-none remove-chrome-outline focus:ring-none'

export interface GalleryContentTest2 {
  videoOne: PathsTypes2
  coverImageVideoOne: PathsTypes2
  videoTwo: PathsTypes2
  coverImageVideoTwo: PathsTypes2
  videoThree: PathsTypes2
  coverImageVideoThree: PathsTypes2
  videoFour: PathsTypes2
  coverImageVideoFour: PathsTypes2
}

export interface VideosType {
  image: PathsTypes2
  video: PathsTypes2
}

export interface PathsTypes2 {
  title: string
  path: string
  meta: MetaPath
}

export interface MetaPath {
  title: string
}

const VideoGallery = (props: {
  arrThemen: GalleryContentTest2[]
  arrPortfolio: GalleryContentTest2[]
  arrStory: GalleryContentTest2[]
}) => {
  const videos = images
  const videosArray: any = []
  const videosArrayPort: any = []
  const videosArrayStory: any = []
  const [active, setCurrent] = useState(-1)
  const ref = useRef<ReactPlayer>(null)
  const [path, setPath] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [playing, setPlaying] = useState(true)
  const [played, setPlayed] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [duration, setDuration] = useState(0)
  const [muted, setMuted] = useState(true)
  const [visible, setVisible] = useState(0)
  const closeVideo = () => {
    setCurrent(-1)
    setPlayed(0)
    setVisible(0)
    setPlaying(true)
  }
  const onDown = () => {
    setSeeking(true)
  }
  const onUp = () => {
    setSeeking(false)
  }
  const showMenu = duration * played <= duration * visible + 3 || !playing

  //Themen
  props.arrThemen.map((x: any, i: number) => {
    if (x.videoOne !== null) {
      const obj = {
        image: x.coverImageVideoOne,
        video: x.videoOne
      }
      videosArray.push(obj)
    }
    if (x.videoTwo !== null) {
      const obj = {
        image: x.coverImageVideoTwo,
        video: x.videoTwo
      }
      videosArray.push(obj)
    }
    if (x.videoThree !== null) {
      const obj = {
        image: x.coverImageVideoThree,
        video: x.videoThree
      }
      videosArray.push(obj)
    }
    if (x.videoFour !== null) {
      const obj = {
        image: x.coverImageVideoFour,
        video: x.videoFour
      }
      videosArray.push(obj)
    }
  })

  //Portfolio
  props.arrPortfolio.map((x: any, i: number) => {
    if (x.videoOne !== null) {
      const obj = {
        image: x.coverImageVideoOne,
        video: x.videoOne
      }
      videosArrayPort.push(obj)
    }
    if (x.videoTwo !== null) {
      const obj = {
        image: x.coverImageVideoTwo,
        video: x.videoTwo
      }
      videosArrayPort.push(obj)
    }
    if (x.videoThree !== null) {
      const obj = {
        image: x.coverImageVideoThree,
        video: x.videoThree
      }
      videosArrayPort.push(obj)
    }
    if (x.videoFour !== null) {
      const obj = {
        image: x.coverImageVideoFour,
        video: x.videoFour
      }
      videosArrayPort.push(obj)
    }
  })

  //Story
  props.arrStory.map((x: any, i: number) => {
    if (x.videoOne !== null) {
      const obj = {
        image: x.coverImageVideoOne,
        video: x.videoOne
      }
      videosArrayStory.push(obj)
    }
    if (x.videoTwo !== null) {
      const obj = {
        image: x.coverImageVideoTwo,
        video: x.videoTwo
      }
      videosArrayStory.push(obj)
    }
    if (x.videoThree !== null) {
      const obj = {
        image: x.coverImageVideoThree,
        video: x.videoThree
      }
      videosArrayStory.push(obj)
    }
    if (x.videoFour !== null) {
      const obj = {
        image: x.coverImageVideoFour,
        video: x.videoFour
      }
      videosArrayStory.push(obj)
    }
  })

  const handleActive = (i: number, x: string, y: PathsTypes2) => {
    setCurrent(i)
    setPath(x)
    if (y.meta !== undefined) {
      setVideoTitle(y.meta.title)
    } else {
      setVideoTitle(`Video ${i}`)
    }
  }

  return (
    <Gallery>
      {videosArray.length > 0 ? (
        videosArray.map((x: VideosType, i: number) => {
          return (
            <div
              key={i}
              role='button'
              onClick={() => handleActive(i, x.video.path, x.image)}
              className='flex-1 w-992 rounded-xl drop-shadow-xl filter scroll-align-start border-0 border-transparent grid bg-white page-video-grid-template'
              style={{ maxWidth: '33%' }}
            >
              <div className='h-full overflow-hidden rounded-t-xl relative '>
                <img
                  src={`${x.image.path}`}
                  id={'v' + i}
                  className='object-contain h-full w-full'
                />
                <div
                  className={`${videoCSS} w-20 h-20 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transform grid place-items-center`}
                >
                  <i className='pl-1 text-base icon icon-Play2' />
                </div>
              </div>
            </div>
          )
        })
      ) : videosArrayPort.length > 0 ? (
        videosArrayPort.map((x: VideosType, i: number) => {
          return (
            <div
              key={i}
              role='button'
              onClick={() => handleActive(i, x.video.path, x.image)}
              className='flex-1 w-992 rounded-xl drop-shadow-xl filter scroll-align-start border-0 border-transparent grid bg-white page-video-grid-template'
              style={{ maxWidth: '33%' }}
            >
              <div className='h-full overflow-hidden rounded-t-xl relative '>
                <img
                  src={x.image.path}
                  id={'v' + i}
                  className='object-contain h-full w-full'
                />
                <div
                  className={`${videoCSS} w-20 h-20 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transform grid place-items-center`}
                >
                  <i className='pl-1 text-base icon icon-Play2' />
                </div>
              </div>
            </div>
          )
        })
      ) : videosArrayStory.length > 0 ? (
        videosArrayStory.map((x: VideosType, i: number) => {
          return (
            <div
              key={i}
              role='button'
              onClick={() => handleActive(i, x.video.path, x.image)}
              className='flex-1 w-992 rounded-xl drop-shadow-xl filter scroll-align-start border-0 border-transparent grid bg-white page-video-grid-template'
              style={{ maxWidth: '33%' }}
            >
              <div className='h-full overflow-hidden rounded-t-xl relative '>
                <img
                  src={x.image.path}
                  id={'v' + i}
                  className='object-contain h-full w-full'
                />
                <div
                  className={`${videoCSS} w-20 h-20 absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transform grid place-items-center`}
                >
                  <i className='pl-1 text-base icon icon-Play2' />
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <p>no videos available for this content</p>
      )}
      {active >= 0 && (
        <div
          className={`bg-darkgray bg-opacity-70 absolute inset-0 backdrop-filter
          backdrop-blur-sm animate-fadein z-10 overflow-hidden`}
        >
          <ReactPlayer
            className='absolute inset-0 w-full h-full grid '
            url={`https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com${path}`}
            playing={playing}
            onEnded={() => {
              setPlaying(false)
              setVisible(0)
              setPlayed(0)
            }}
            ref={ref}
            width='100%'
            height='100%'
            onProgress={({ played }) => {
              if (!seeking) {
                setPlayed(played)
              }
            }}
            //muted={muted}
            volume={0.5}
            onDuration={(duration) => {
              setDuration(duration)
            }}
            config={
              {
                file: { attributes: { disablePictureInPicture: 1 } }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any
            }
          />
          <div
            className={`absolute inset-0 flex flex-col justify-between transition-opacity ${
              showMenu ? '' : 'opacity-0'
            }`}
            onClick={() => setVisible(played)}
          >
            <div className='h-40 pointer-events-auto'>
              <CloseButton action={closeVideo} />
            </div>
            <div className='px-36 pb-2 pointer-events-auto'>
              <div className='flex flex-row justify-center gap-20 items-end'>
                <button onClick={closeVideo}>
                  <div
                    className={`${videoCSS} w-14 h-14`}
                    style={{
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      color: '#fff'
                    }}
                  >
                    <i className='text-xs text-white icon icon-Einloggen1' />
                  </div>
                  <p
                    className='text-white'
                    style={{
                      color: '#fff',
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      borderRadius: '5px'
                    }}
                  >
                    Verlassen
                  </p>
                </button>
                <button
                  onClick={() => {
                    setPlaying(!playing)
                  }}
                >
                  <div
                    className={`${videoCSS} w-44 h-44`}
                    style={{
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      color: '#fff'
                    }}
                  >
                    <i
                      className={`text-lg text-white icon icon-${
                        playing ? 'Pause2' : 'Play2'
                      }`}
                    />
                  </div>
                  <div
                    className='text-white'
                    style={{
                      color: '#fff',
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      borderRadius: '5px',
                      outline: 'none'
                    }}
                  >
                    {playing ? 'Pausieren' : 'Abspielen'}
                  </div>
                </button>
                <button
                  onClick={() => {
                    if (active < videos.length - 1) {
                      setCurrent(active + 1)
                      setPlayed(0)
                      setPlaying(true)
                    }
                  }}
                >
                  <div
                    className={`${videoCSS} w-14 h-14`}
                    style={{
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      color: '#fff'
                    }}
                  >
                    <i className='text-xs text-white icon icon-vor' />
                  </div>
                  <p
                    className='text-white'
                    style={{
                      color: '#fff',
                      backgroundColor: 'rgb(0, 0, 0, 0.3)',
                      borderRadius: '5px'
                    }}
                  >
                    Nächstes
                  </p>
                </button>
              </div>
              <div className='flex flex-row justify-end text-white pb-4 '>
                <button
                  onClick={() => {
                    setMuted(!muted)
                  }}
                >
                  {muted ? 'Mach laut' : 'mach leise'}
                </button>
              </div>
              <input
                className='w-full progress'
                type='range'
                min={0}
                max={0.999999}
                step='any'
                value={played}
                onMouseDown={onDown}
                onChange={(e) => {
                  if (!seeking) {
                    ref.current?.seekTo(parseFloat(e.target.value))
                    setVisible(parseFloat(e.target.value) * duration)
                  }
                }}
                onMouseUp={onUp}
              />

              <div className='flex flex-row justify-between text-gray-300 italic text-base pt-4 pb-8'>
                <span>{format(duration * played)}</span>
                <span>{format(duration)}</span>
              </div>
            </div>
          </div>
        </div>
      )}{' '}
      <div className='pl-8'></div>
    </Gallery>
  )
}
