import React from 'react'
import './contact.css'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as api from '../../CockData'

export interface ContactTypes {
  firstName: string
  lastName: string
  telePhone: string
  jobTitle: string
  personPhoto: {
    path: string
  }
  personQr: {
    path: string
  }
  description: string
}

export interface MapTypes {
  value: ContactTypes[]
  Firmennamen: string
  Ansprechpartner: ContactTypes[]
}

export const Contact = (): JSX.Element => {
  const { exhibitor } = useParams<{ exhibitor: string }>()
  const { data, status } = useQuery('Collections', api.getData)
  const arrContact: any = []

  status === 'success' &&
    data.map((y: any) => {
      if (exhibitor === y.Firmennamen.Firmennamen) {
        y.Firmennamen.Ansprechpartner &&
          y.Firmennamen.Ansprechpartner.map((ele: any) => {
            arrContact.push(ele.value)
          })
      }
    })

  return arrContact.map((t: ContactTypes, i: number) => (
    <div
      key={i}
      className='contact-container pb-9 text-opacity-60 text-black max-w-3xl ml-auto'
    >
      <div className='contact-name font-light text-right text-xs'>
        {t.firstName} {''} {t.lastName}
        <span className='block text-sm font-normal'>{t.jobTitle}</span>
      </div>
      <div className='contact-bubble  '>
        <p className='filter drop-shadow-xl blur-0 rounded-xl bg-white px-5 pt-4 pb-4 text-right text-xs'>
          „{t.description}“
        </p>
      </div>
      <div className='contact-image pt-20'>
        <img
          src={t.personPhoto?.path}
          className='object-cover rounded-full w-24 h-24 m-auto filter drop-shadow-xl'
        />
      </div>
      <div className='contact-contact text-xs text-right my-auto'>
        Meine Visitenkarte herunterladen:
      </div>
      <div className='contact-qr pt-1'>
        <img src={t.personQr?.path} className='w-14 h-14 m-auto' />
      </div>
    </div>
  ))
}
