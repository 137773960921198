import React, { useRef } from 'react'
import { Exhibitors } from './exhibitor'
import { useQuery } from 'react-query'
import * as api from '../../CockData'
import { useParams } from 'react-router-dom'

export interface path {
  path: string
}

export interface Idle {
  Firmennamen: {
    Firmennamen: string
    _id: string
    Bilder: Array<path>
    Logo: {
      path: string
    }
    Videos: {
      path: string
    }
  }
}

export const Idle = (): JSX.Element => {
  const { data, status } = useQuery('Collections', api.getData)
  const { data: dataMachines, status: statusMachine } = useQuery(
    'Machines',
    api.getMachine
  )
  const machineId: any = []
  const finalData: any = []
  //const gridClass = data?.length === 4 ? 'grid-cols-2 grid-rows-2' : ''
  const gridClass = finalData?.length === 4 ? 'grid-cols-2 grid-rows-2' : ''

  const { machine }: { machine: string } = useParams()

  statusMachine === 'success' &&
    dataMachines.map((c: any) => {
      if (c.Name === machine) {
        c.Companies.map((id: any) => {
          machineId.push(id._id)
        })
      }
    })

  data?.map((c: Idle) => {
    machineId.map((i: any) => {
      if (c.Firmennamen._id === i) {
        finalData.push(c)
      }
    })
  })

  //Redirect after 3 minuten
  const lastTouch = useRef(0)
  const timeoutClick = useRef<NodeJS.Timeout | null>(null)
  const mainClick = document.getElementById('main')
  mainClick &&
    mainClick.addEventListener('touchstart', () => {
      lastTouch.current = new Date().getMinutes()
      timeoutClick.current = setInterval(function () {
        if (new Date().getMinutes() - lastTouch.current >= 3) {
          console.log('se acabo el tiempo')
          window.location.href = `/${machine}`
          timeoutClick.current && clearInterval(timeoutClick.current)
        }
      }, 1000)
    })

  return (
    <div className='absolute left-0 right-0 top-0 inset-0 select-none bg-white	'>
      <div className={`grid h-full ${gridClass}`}>
        {status === 'success' &&
          finalData?.map((x: Idle) => (
            <Exhibitors
              machine={machine}
              key={x.Firmennamen._id}
              video={x.Firmennamen.Videos?.path}
              logo={x.Firmennamen.Logo.path}
              name={x.Firmennamen.Firmennamen}
            ></Exhibitors>
          ))}
      </div>
    </div>
  )
}
