import React from 'react'
import { Link } from 'react-router-dom'
import './exhibitor.css'

export interface ExhibitorsProps {
  logo: string
  name: string
  video: string
  machine: string
}

export const Exhibitors = ({
  logo,
  name,
  video,
  machine
}: ExhibitorsProps): JSX.Element => {
  return (
    <div className='grid ex-grid-template h-full '>
      <Link to={`/${name}/${machine}`}>
        <div
          className='h-60 grid place-items-center ex-gradient relative border-1
          border-b-0 border-gray-300 py-10'
        >
          <img src={logo} className='invert block h-32 w-auto'></img>
        </div>
      </Link>
      <div className='relative overflow-hidden '>
        <Link to={`/${name}/${machine}`}>
          <video
            src={`https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com${video}`}
            muted
            autoPlay
            loop
            className='object-cover top-0 left-0 w-full h-full absolute block bg-black'
          ></video>
        </Link>
      </div>
    </div>
  )
}
