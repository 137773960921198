import React from 'react'
import { Snaptabs } from './Components/snaptabs/snaptabs'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom'
import './App.css'
import { Idle } from './Components/idle'
import { Page } from './Components/page'
import { Portfolio } from './Components/portfolio'
import { Themen } from './Components/themen'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const App = (): JSX.Element => {
  const effectClasses = (pathe: string) => {
    if (pathe.includes('page')) {
      return 'page'
    } else if (pathe.includes('themen') || window.location.hash === '#hello') {
      return 'themen'
    } else if (
      pathe.includes('portfolio') ||
      window.location.hash === '#hello'
    ) {
      return 'portfolio'
    } else if (pathe.includes('story') || window.location.hash === '#hello') {
      return 'story'
    } else {
      return 'page'
    }
  }

  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup component={null}>
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames={effectClasses(location.pathname)}
              mountOnEnter={false}
              unmountOnExit={true}
            >
              <Switch location={location}>
                <Route exact path='/:machine'>
                  <Idle />
                </Route>
                <Route path='/:exhibitor/:machine'>
                  <ExhibitorsRoutes />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  )
}

const ExhibitorsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Snaptabs></Snaptabs>
      </Route>
      <Route exact path={`${path}/portfolio`}>
        <Portfolio></Portfolio>
      </Route>
      <Route exact path={`${path}/themen`}>
        <Themen></Themen>
      </Route>
      <Route exact path={`${path}/page/:id/:section`}>
        <Page></Page>
      </Route>
    </Switch>
  )
}

/*const TopicsRoutes = () => {
  const {
    path,
    params: { area, topicID }
  } = useRouteMatch<{ area: string; topicID: string }>()
  return (
    <Switch>
      <Route path={`${path}`}>
        <div>{area} Overview</div>
      </Route>
      <Route path={`${path}/:topicID`}>
        <div>
          {area} details: {topicID}{' '}
        </div>
      </Route>
    </Switch>
  )
}*/
export default App
