import axios from 'axios'

//GETTING THE DATA

/*const getPostById = async (postId) => {
    const { data } = await axios.get(`${url}/${postId}`)
    return data
  }

  export default function usePost(postId) {
    return useQuery(['post', postId], () => getPostById(postId))
  }*/

//this one
/*axios.get(url).then((res) => {
    const posts = res.data
    console.log(posts)
  })*/

//const queryClient = new QueryClient()

/*const fetchData = async () => {
    const result = await fetch(url)
    return result.json()
  }

  const { data, status } = useQuery('posts', fetchData)
  console.log(data)*/

//this one works, old
/*const url =
    'https://cms.hybrid-x.de' +
    '/api/collections/get/' +
    'Company_B' +
    '?token=d8d53c8675f5efd15a8dc650609e51'

  const usePosts = () => {
    return useQuery('posts', async () => {
      const { data } = await axios.get(url)
      return data.entries
    })
  }

  const { status, data, error, isFetching } = usePosts()
  console.log(data)*/

const MachinesURL =
  'https://cms.hybrid-x.de/api/collections/get/DigitalBoothMachines'

const TopicsURL =
  'https://cms.hybrid-x.de/api/collections/get/topics?token=91966d4925e5e6d6aac37d8f0b7c63'

const baseURL = 'https://cms.hybrid-x.de/api/collections/get/Company_B'

const token = '91966d4925e5e6d6aac37d8f0b7c63'

const config = {
  headers: { Authorization: `Bearer ${token}` }
}

const body = {
  populate: 1
}

const json = JSON.stringify(body)
const newJson = JSON.parse(json)

/* export const getData = async (id) => {
  const { data: machines } = await axios.get(machineUrl, {
    filter: { _id: id }
  })
  return await axios
    .post(baseURL, newJson, config)
    .then((res) => res.data.entries)
} */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMachine = async () => {
  const { data } = await axios.get(MachinesURL, config)
  return data.entries
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getData = async () =>
  await axios.post(baseURL, newJson, config).then((res) => res.data.entries)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const topicAPI = async () => {
  const { data } = await axios.get(TopicsURL)
  return data.entries
}

//THIS IS THE ONE
/*export const getData = async () =>
  await axios
    .get(
      'https://cms.hybrid-x.de' +
        '/api/collections/get/' +
        'Company_B' +
        '?token=d8d53c8675f5efd15a8dc650609e51'
    )
    .then((res) => res.data.entries)*/

/*const api = () =>
	useQuery('posts', async () => {

		const { data } = await axios.post(baseURL, {
			populate: 1,
		})
		return data
	})

export default api*/
