import React, { useState, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CloseButton } from '../closeButton'
import { Contact } from '../Contact/Contact'
import { AlleThemenButton, PortfolioButton } from '../page/Button'
import { useQuery } from 'react-query'
import * as api from '../../CockData'

export interface ArrTypes {
  coverimage: {
    path: string
  }
  id: number
  titleCover: string
  contentCover: string
  subtitleCover: string
  sectionRoot: string
  sectionTitle: string
}

export const Themen = (): JSX.Element => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const logoFirma = useRef('')
  const topicsIdCompany: any = []
  const arr: any = []
  const arrTop: ArrTypes[] = []
  const arrDown: ArrTypes[] = []
  const topTitle = useRef('')
  const downTitle = useRef('')
  const { status: StatusTopic, data: dataTopic } = useQuery(
    'topics',
    api.topicAPI
  )
  const { data } = useQuery('Collections', api.getData)
  data &&
    data.map((y: any) => {
      if (exhibitor === y.Firmennamen.Firmennamen) {
        if (y.Bilder !== null) {
          logoFirma.current = y.Bilder[0].path
        }
        y.Firmennamen.Topics.map((t: any) => {
          topicsIdCompany.push(t._id)
        })
        y.Firmennamen.Themen &&
          y.Firmennamen.Themen.map((ele: any, i: number) => {
            ele.value.id = i + 1
            arr.push(ele.value)
            if (ele.value.sectionRoot === 'top') {
              arrTop.push(ele.value)
              topTitle.current = arrTop[0].sectionTitle
            } else {
              arrDown.push(ele.value)
              downTitle.current = arrDown[0].sectionTitle
            }
            //setResult([...result, ele.value])
          })
      }
    })

  const TopicsTop: any[] = []
  const TopicsDown: any[] = []

  topicsIdCompany.length > 0 &&
    topicsIdCompany.map((Tc: any) => {
      StatusTopic === 'success' &&
        dataTopic.map((t: any, i: number) => {
          if (t._id === Tc) {
            if ((topicsIdCompany.length % 2 === 0) === true) {
              const value = topicsIdCompany.length / 2
              if (value < i) {
                TopicsTop.push(t)
              } else {
                TopicsDown.push(t)
              }
            } else {
              if (i <= Math.round(topicsIdCompany.length / 2)) {
                TopicsTop.push(t)
              } else {
                TopicsDown.push(t)
              }
            }
          }
        })
    })

  //Redirect after 3 minuten
  const lastTouch = useRef(0)
  const timeoutClick = useRef<NodeJS.Timeout | null>(null)
  const mainClick = document.getElementById('main')
  mainClick &&
    mainClick.addEventListener('touchstart', () => {
      lastTouch.current = new Date().getMinutes()
      timeoutClick.current = setInterval(function () {
        if (new Date().getMinutes() - lastTouch.current >= 3) {
          window.location.href = `/${machine}`
          timeoutClick.current && clearInterval(timeoutClick.current)
        }
      }, 1000)
    })

  return (
    <div className='absolute left-0 right-0 top-0 inset-0 select-none	'>
      <div className='page grid page-grid-template h-full'>
        <div
          className='h-40 bg-headblue relative bg-cover drop-shadow-2xl rounded-b-xl'
          style={{
            backgroundImage:
              'url(https://source.unsplash.com/TcrkAz1yYPI/1600x500)'
          }}
        >
          <div
            className='object-cover absolute top-0 left-0 w-full h-full backdrop-filter
            backdrop-blur-2xl backdrop-brightness-20 bg-headblue bg-opacity-25 flex
            flex-row-reverse justify-between '
          >
            {/*<CloseButton action={() => history.push(`/${exhibitor}`)} />*/}
            <div className='flex justify-end pt-9 pr-40 pb-8'>
              <img
                src={logoFirma.current}
                className='block h-full w-auto'
              ></img>
            </div>
            <h3 className='pl-36 pb-2 text-white my-auto'>Themen:</h3>
          </div>
        </div>
        <main className='bg-lightgray pl-36 pt-8 pb-2 grid grid-cols-1 gap-32 overflow-hidden'>
          <div className='grid grid-rows-2 overflow-hidden'>
            <section className='flex flex-col overflow-hidden'>
              <ImageGallery TopicsTop={TopicsTop} />
            </section>
            <section className='flex flex-col overflow-hidden'>
              <ImageGalleryDown TopicsDown={TopicsDown} />
            </section>
          </div>
        </main>
        <Footer logoFirma={logoFirma.current} />
      </div>
    </div>
  )
}

export interface FooterLogo {
  logoFirma: string
}
const Footer = (logoFirma: FooterLogo) => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const history = useHistory()

  return (
    <div className='flex flex-row justify-between bg-lightgray'>
      <div className='flex-1 pl-10 pt-22 pb-16 h-16'>
        <CloseButton
          colorFul
          action={() => history.push(`/${exhibitor}/${machine}`)}
        />
      </div>
      <div className='flex-1 text-center flex flex-row justify-between pt-22'>
        <AlleThemenButton />
        <Link
          to={`/${exhibitor}/${machine}`}
          className='flex flex-col items-center'
        >
          <span className='text-white filter drop-shadow-lg w-24 h-24 rounded-full bg-gradient-to-br from-lime to-turquoise grid place-content-center mb-9'>
            <i className='text-icon icon icon-Pfeil2' />
          </span>
          <p>Zur Story</p>
        </Link>
        <PortfolioButton />
      </div>
      <div className='flex-1 pl-12 pr-2 relative '>
        <Contact />
      </div>
    </div>
  )
}

interface GalleryProps {
  className?: string
}
const Gallery = ({
  className,
  children
}: React.PropsWithChildren<GalleryProps>) => {
  return (
    <div
      className={`overflow-x-auto w-full flex-1 flex scroll-snap-x ${
        className ? className : ''
      }`}
    >
      <div className='flex flex-row flex-nowrap flex-grow gap-14 flex-auto pb-10 '>
        {children}
      </div>
    </div>
  )
}

export interface GalleryTypes {
  coverimage: {
    path: string
  }
  id: number
  titleCover: string
  contentCover: string
  subtitleCover: string
  sectionRoot: string
}

export interface TopicsTypes {
  _id: string
  topic_title: string
  content: string
  image: {
    path: string
  }
  topic_id: string
}

const ImageGallery = (props: { TopicsTop: TopicsTypes[] }) => {
  return (
    <Gallery>
      {props.TopicsTop.map((x: TopicsTypes, i: number) => (
        <Link key={i} to={`page/${x.topic_id}/themen`}>
          <div
            style={{
              backgroundImage: `url('http://cms.hybrid-x.de/${x.image.path}')`,
              width: '25vw',
              height: '12vw',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex'
            }}
            className='flex-1 block min-h-0 last:mr-1 cursor-pointer rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9'
          >
            <div className='flex flex-col px-5 pb-24 self-end'>
              <div className='text-white'>{x.topic_title}</div>
            </div>
          </div>
        </Link>
      ))}
    </Gallery>
  )
}

const ImageGalleryDown = (props: { TopicsDown: TopicsTypes[] }) => {
  return (
    <Gallery>
      {props.TopicsDown.map((x: TopicsTypes, i: number) => (
        <Link key={i} to={`page/${x.topic_id}/themen`}>
          <div
            style={{
              backgroundImage: `url('http://cms.hybrid-x.de/${x.image.path}')`,
              width: '25vw',
              height: '12vw',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex'
            }}
            className='flex-1 block min-h-0 last:mr-1 cursor-pointer rounded-xl drop-shadow-xl filter scroll-align-start scroll-px-9'
          >
            <div className='flex flex-col px-5 pb-24 self-end'>
              <div className='text-white'>{x.topic_title}</div>
            </div>
          </div>
        </Link>
      ))}
    </Gallery>
  )
}
