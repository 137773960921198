import React from 'react'

interface CloseButtonProps {
  action?: () => void
  smallPadding?: boolean
  colorFul?: boolean
}
export const CloseButton = ({
  action,
  smallPadding,
  colorFul
}: CloseButtonProps): JSX.Element => {
  const padding = smallPadding ? 'pl-28' : 'pl-36'
  const color = colorFul ? 'text-black' : 'text-white'
  return (
    <a
      className={`${padding} ${color} pt-9 text-base block w-full`}
      onClick={action}
    >
      <div className='flex flex-row'>
        <i className='icon icon-Einloggen1 text-sm' />
        <p className={`text-sm`}>Verlassen</p>
      </div>
    </a>
  )
}
