import React, { useEffect, useRef, useState } from 'react'
import './styles/index.css'
import images from '../../data'
import { CloseButton } from '../closeButton'
import { AlleThemenButton, PortfolioButton } from '../page/Button'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as api from '../../CockData'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'snap-tabs': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

export interface StoriesTypes {
  Firmennamen: {
    storyImage: {
      path: string
    }
    img: {
      path: string
    }
    storyTitle: string
    subtitleCover: string
    id: number
    path: string
  }
}

export const Snaptabs = (): JSX.Element => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const curIdx = useRef(0)
  const lengthing = useRef(0)
  const curAnimation = useRef<Animation | null>(null)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const scroll = useRef<HTMLDivElement>(null)
  const [index, setIndex] = useState<number>(0)
  const logoFirma = useRef('')
  const arr: any = []
  const lastTouch = useRef(0)
  const timeoutClick = useRef<NodeJS.Timeout | null>(null)
  const mainClick = document.getElementById('main')
  const { data, status } = useQuery('Collections', api.getData)

  status === 'success' &&
    data.map((y: any) => {
      if (exhibitor === y.Firmennamen.Firmennamen) {
        if (y.Bilder !== null) {
          logoFirma.current = y.Bilder[0].path
        }
        y.Firmennamen.Story &&
          y.Firmennamen.Story.map((ele: any, i: number) => {
            ele.value.id = i
            ele.value.id === 0 && ele.value.id + 1
            arr.push(ele.value)
            lengthing.current = arr.length
          })
      }
    })

  //Redirect after 3 minuten
  mainClick &&
    mainClick.addEventListener('touchstart', () => {
      lastTouch.current = new Date().getMinutes()
      timeoutClick.current = setInterval(function () {
        if (new Date().getMinutes() - lastTouch.current >= 3) {
          window.location.href = `/${machine}`
          timeoutClick.current && clearInterval(timeoutClick.current)
        }
      }, 1000)
    })

  const callback = (idx: number) => {
    curIdx.current = idx
    // cancel current animation
    if (curAnimation.current) {
      curAnimation.current.cancel()
      curAnimation.current = null
    }

    const progress = document.getElementById(`nav${curIdx.current}`)

    if (progress) {
      // animate with scale, because of performance
      curAnimation.current = progress.animate(
        [{ transform: 'scale3d(0, 1, 1)' }, { transform: 'scale3d(1, 1, 1)' }],
        {
          duration: 5000,
          iterations: 1,
          easing: 'linear'
        }
      )
      curAnimation.current.onfinish = () => {
        const nextIdx =
          curIdx.current + 1 >= lengthing.current ? 0 : curIdx.current + 1
        document.getElementById(nextIdx.toString())?.scrollIntoView()
        callback(nextIdx)
      }
    }
  }
  useEffect(() => {
    setTimeout(function () {
      callback(curIdx.current)
    }, 1000)
    //callback(curIdx.current)
    return () => {
      curAnimation.current?.cancel()
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  scroll.current?.addEventListener('scroll', () => {
    if (scroll.current) {
      const i = scroll.current.scrollLeft / scroll.current?.clientWidth
      curAnimation.current?.cancel()
      // cancel previous timeout
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      // sets a new timeout to continue with autoscroll
      setIndex(Math.ceil(i))
      timeout.current = setTimeout(() => callback(i), 100)
      /*images.map((content, key) => {
        if (key === i) {
          //here sending index IMPORTANT
          //console.log(content.content)
        }
      })*/
    }
  })

  const handleProgress = () => null
  const history = useHistory()

  return (
    <div className='absolute left-0 right-0 top-0 inset-0 select-none	'>
      <div className='flex flex-col h-full overflow-hidden drop-shadow-xl'>
        <snap-tabs
          onTouchStart={() => {
            // cancel current timeline
            curAnimation.current?.cancel()
            // cancel previous timeout
            if (timeout.current) {
              clearTimeout(timeout.current)
            }
            // sets a new timeout to continue with autoscroll
            timeout.current = setTimeout(() => callback(curIdx.current), 20000)
          }}
        >
          <div className='container-header'>
            <div className='pt-24'></div>
            <header className='scroll-snap-x header-snap-tabs'>
              <nav className='container-nav'>
                {arr.map((img: any, i: number) => (
                  <SnapLink key={i} index={i} callback={callback} />
                ))}
              </nav>
              <span className='snap-indicator'></span>
            </header>
          </div>
          <section
            ref={scroll}
            className='scroll-snap-x section-snap-tabs rounded-b-2xl '
          >
            {arr.map((img: any, i: number) => (
              <article
                className='item-snap-tabs relative'
                key={i}
                id={`${img.id}`}
              >
                <div className='img-snap-tabs '>
                  <img
                    src={img.storyImage.path}
                    id={`${img.id}`}
                    className='object-cover w-full h-full'
                  />
                </div>
                <div className='back-touch'>
                  <a
                    className='icon-left'
                    href={`#${i > 0 ? img.id - 1 : ''}`}
                    onClick={() => handleProgress()}
                  >
                    <i className='icon icon-Tap2' />
                  </a>
                  <div className='slider-text-container pl-28'>
                    <div className='slider-title text-white font-light'>
                      {img.storyTitle}
                    </div>
                    <div className='slider-text font-bold'>
                      {img.storySubTitle}
                    </div>
                  </div>
                </div>

                <div className='forward-touch'>
                  <div className='icon-touch'>
                    <i className='icon icon-Swipe' />
                  </div>
                  <a
                    className='icon-right'
                    href={`#${img.id + 1}`}
                    onClick={() => handleProgress()}
                  >
                    <i className='icon icon-Tap1' />
                  </a>
                </div>
              </article>
            ))}
          </section>
        </snap-tabs>
        <div className='grid grid-cols-5 h-48 bg-lightgray'>
          <div className='flex-1 pl-0 pt-22 pb-16 h-80'>
            <CloseButton
              smallPadding
              colorFul
              action={() => {
                history.push(`/${machine}`)
              }}
            />
          </div>
          <div className='col-span-3 grid-cols-3 grid pt-12'>
            <AlleThemenButton />
            <Link
              to={`/${exhibitor}/${machine}/page/${index}/story`}
              className='flex flex-col items-center transform -translate-y-32'
            >
              <span className='text-white filter drop-shadow-lg w-36 h-36 rounded-full bg-gradient-to-br from-lime to-turquoise grid place-content-center mb-9'>
                <i className={`text-icon icon icon-Plus2`} />
              </span>
              <span className='mt-2 text-opacity-80'>
                <p className={`text-sm`}>Zur Story</p>
              </span>
            </Link>
            <PortfolioButton />
          </div>
          <div className='flex-1 pr-36 pt-12 pb-20 h-80 flex justify-end'>
            <img src={logoFirma.current} className='h-full w-auto'></img>
          </div>
        </div>
      </div>
    </div>
  )
}

type ImageType = typeof images[0]

interface SnapLinkProps {
  //img: ImageType
  index: number
  callback: (n: number) => void
}

const SnapLink = ({ index, callback }: SnapLinkProps) => {
  return (
    <a className='nav-items' href={`#${index}`} onClick={() => callback(index)}>
      <span className='pl-1 text-gray-300'>Topic {index}</span>
      <div className='item-line mb-9'>
        <div id={`nav${index}`} className='progress'></div>
      </div>
    </a>
  )
}
