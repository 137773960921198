import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

export type HybridxIconsId =
  | 'Ablage'
  | 'Akku-fast-leer'
  | 'Akku-halb-voll'
  | 'Akku-leer'
  | 'Akku-voll'
  | 'Armbanduhr'
  | 'Auge-aus'
  | 'Auge'
  | 'auseinander'
  | 'Ausloggen1'
  | 'Ausloggen2'
  | 'Bankkarte'
  | 'Bild'
  | 'Bildschirm'
  | 'Blitz'
  | 'Blitz1'
  | 'Blitz2'
  | 'Brief-offen'
  | 'Brief'
  | 'Brieftasche'
  | 'Buch'
  | 'Buroklammer'
  | 'Daumen-hoch'
  | 'Diamant'
  | 'Dokument-neu'
  | 'Dokument1'
  | 'Dokument2'
  | 'Dokument3'
  | 'Doppelhaken'
  | 'Download1'
  | 'Download3'
  | 'Drucker'
  | 'Einkaufswagen'
  | 'Einloggen1'
  | 'Einloggen2'
  | 'Einstellungen'
  | 'Etikett'
  | 'Fahne'
  | 'Feuer1'
  | 'Feuer2'
  | 'Filter1'
  | 'Filter2'
  | 'Fokus'
  | 'Fokus2'
  | 'Fokus3'
  | 'Frequenz'
  | 'Geldschein'
  | 'Geschenk'
  | 'Globus'
  | 'Glocke'
  | 'Grafik'
  | 'Haken1'
  | 'Handy1'
  | 'Handy2'
  | 'Haus'
  | 'Herz'
  | 'Hinzufuegen'
  | 'Icons-87'
  | 'Kalender'
  | 'Kamera-an'
  | 'Kamera-aus'
  | 'Kamera'
  | 'Karte'
  | 'Kompass'
  | 'Korb'
  | 'Korb2'
  | 'Kreuz1'
  | 'Kreuz2'
  | 'Krone'
  | 'Laden'
  | 'Laptop'
  | 'Liste'
  | 'Loading'
  | 'Lupe'
  | 'Lupe2'
  | 'Lupe3'
  | 'Megaphone'
  | 'Mehr-Personen'
  | 'Mehr'
  | 'Merken-neu'
  | 'Merken'
  | 'Microphone-an'
  | 'Microphone-aus'
  | 'Microphone'
  | 'Minus1'
  | 'Mond'
  | 'Mulleimer'
  | 'Musik'
  | 'Navigation1'
  | 'Navigation2'
  | 'Nebel'
  | 'Neu'
  | 'Off'
  | 'Ordner1'
  | 'Ordner2'
  | 'Pause2'
  | 'Person'
  | 'Personen'
  | 'Pfeil1'
  | 'Pfeil10'
  | 'Pfeil11'
  | 'Pfeil12'
  | 'Pfeil13'
  | 'Pfeil14'
  | 'Pfeil15'
  | 'Pfeil16'
  | 'Pfeil17'
  | 'Pfeil18'
  | 'Pfeil19'
  | 'Pfeil2'
  | 'Pfeil20'
  | 'Pfeil21'
  | 'Pfeil3'
  | 'Pfeil4'
  | 'Pfeil5'
  | 'Pfeil6'
  | 'Pfeil7'
  | 'Pfeil8'
  | 'Pfeil9'
  | 'Pin1'
  | 'Pin2'
  | 'Play1'
  | 'Play2'
  | 'Plus1'
  | 'Plus2'
  | 'Profil'
  | 'Rad'
  | 'Rakete'
  | 'Raster1'
  | 'Raster2'
  | 'Raster3'
  | 'Raster4'
  | 'Raster5'
  | 'Regen'
  | 'Schere'
  | 'Schloss-offen'
  | 'Schloss-zu'
  | 'Schluessel'
  | 'Schreiben1'
  | 'Schreiben2'
  | 'Sicherheit1'
  | 'Sicherheit2'
  | 'Sicherheit3'
  | 'Skalieren1'
  | 'Skalieren2'
  | 'Skalieren3'
  | 'Skalieren4'
  | 'Sonne'
  | 'Sprechblase1'
  | 'Sprechblase2'
  | 'Stapel'
  | 'Statistik'
  | 'Stern'
  | 'Stopp1'
  | 'Stoppuhr'
  | 'Switch1'
  | 'Switch2'
  | 'Teil'
  | 'Telefon-aus'
  | 'Telefon'
  | 'Text'
  | 'Text1'
  | 'Text3'
  | 'Text4'
  | 'Text5'
  | 'Text6'
  | 'Text7'
  | 'Text8'
  | 'Ticket'
  | 'Ton-an'
  | 'Ton-aus'
  | 'Tropfen'
  | 'Uhr1'
  | 'Uhr2'
  | 'Unendlich'
  | 'Unendlich2'
  | 'Upload1'
  | 'Upload3'
  | 'Verbindung1'
  | 'Verbindung2'
  | 'Verbot'
  | 'Verknuepfung'
  | 'Vollbild'
  | 'vor'
  | 'vorspulen'
  | 'Wecker'
  | 'Wellen'
  | 'Wolke'
  | 'Zeitung'
  | 'zurueck'
  | 'zurueckspulen'
  | 'zusammen'
  | 'Zuschneiden'

interface ButtonProps {
  text: string
  path: string
  icon?: HybridxIconsId
}
const ButtonPortfolio = ({ text, path, icon }: ButtonProps) => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const [port, setPort] = useState(false)
  useEffect(() => {
    if (window.location.pathname === `/${exhibitor}/portfolio`) {
      setPort(true)
    }
  }, [port])
  return !port ? (
    <Link
      to={`/${exhibitor}/${machine}/${path}`}
      className='flex flex-col items-center text-black text-opacity-80'
    >
      <span className='w-14 h-14 rounded-full grid place-content-center mb-9 '>
        {icon ? <i className={`text-icon icon icon-${icon}`} /> : '[]'}
      </span>
      <p className={`text-sm`}>{text}</p>
    </Link>
  ) : (
    <div className='flex flex-col items-center text-black text-opacity-80'>
      <span className='w-14 h-14 rounded-full grid place-content-center mb-9 '>
        {icon ? <i className={`text-icon icon icon-${icon}`} /> : '[]'}
      </span>
      <p className={`text-sm`}>{text}</p>
    </div>
  )
}

const ButtonThemen = ({ text, path, icon }: ButtonProps) => {
  const { exhibitor, machine } =
    useParams<{ exhibitor: string; machine: string }>()
  const [themen, setThemen] = useState(false)
  useEffect(() => {
    if (window.location.pathname === `/${exhibitor}/themen`) {
      setThemen(true)
    }
  }, [themen])
  return !themen ? (
    <Link
      to={`/${exhibitor}/${machine}/${path}`}
      className='flex flex-col items-center text-black text-opacity-80'
    >
      <span className='w-14 h-14 rounded-full grid place-content-center mb-9 '>
        {icon ? <i className={`text-icon icon icon-${icon}`} /> : '[]'}
      </span>
      <p className={`text-sm`}>{text}</p>
    </Link>
  ) : (
    <div className='flex flex-col items-center text-black text-opacity-80'>
      <span className='w-14 h-14 rounded-full grid place-content-center mb-9 '>
        {icon ? <i className={`text-icon icon icon-${icon}`} /> : '[]'}
      </span>
      <p className={`text-sm`}>{text}</p>
    </div>
  )
}
export const AlleThemenButton = (): JSX.Element => (
  <ButtonThemen text='Alle Themen' path='themen' icon='Raster3' />
)
export const PortfolioButton = (): JSX.Element => (
  <ButtonPortfolio text='Portfolio' path='portfolio' icon='Raster1' />
)
